<template>
  <v-card>
    <v-card-title class="font-weight-semibold">
      Liste des décodeurs à échéance
    </v-card-title>
    <v-divider></v-divider>
    <br>
    <liste-decodeur-echeance></liste-decodeur-echeance>
  </v-card>
</template>

<script>
// eslint-disable-next-line import/extensions
import listeDecodeurEcheance from '@/views/dashboards/analytics/listeDecodeurEcheance'
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'ListeDecodeurEcheance',
  components: {
    listeDecodeurEcheance,
  },
  setup() {
    const search = ref('')

    return {
      search,

      icon: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style scoped>

</style>
